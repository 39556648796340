
import {
  IonPage, IonContent, IonList, IonItem, IonHeader,
  IonToolbar, IonSearchbar, IonButtons, IonRow,
  IonGrid, IonIcon, IonBackButton, IonItemDivider,
  IonItemGroup, IonProgressBar, IonLabel, IonTitle
} from '@ionic/vue';
import { menuCategories } from "@/config/StaticData";
import { defineComponent, ref } from "vue";
import { searchStoreProducts } from "@/services/Api";
import { searchOutline, thumbsDownOutline } from "ionicons/icons";

export default defineComponent({
  name: 'Search',
  components: {
    IonPage, IonContent, IonList, IonItem, IonHeader,
    IonToolbar, IonSearchbar, IonButtons, IonRow,
    IonGrid, IonIcon, IonBackButton, IonItemDivider,
    IonItemGroup, IonProgressBar, IonLabel, IonTitle
  },
  mounted() {
    const autofocusInput: any = this.$refs.autofocusInput;
    setTimeout(() => {
      autofocusInput.$el.setFocus()
    }, 1000)
  },
  methods: {
    clearSearchTerm: function () {
      this.searchBarUsed = false;
      this.searchInProgress = false;
      this.searchResults = [];
    },
    searchProduct: function (event: any) {
      const searchTerm = event.target.value;
      this.searchTerm = searchTerm;

      if (searchTerm.length !== 0) {
        this.searchInProgress = true;
        searchStoreProducts(searchTerm).then((data) => {
          /* eslint-disable @typescript-eslint/camelcase */
          let searchResultsProducts: { id: number; slug: string; name: string; category: string; short_description: string }[];

          // Remove unneeded properties from store search results
          searchResultsProducts = data.map((product: any) => {
            const {id, slug, name, categories, short_description} = product;
            return {id, slug, name, category: categories[0]['slug'], short_description}
          })

          // Rank results by relevance
          searchResultsProducts = searchResultsProducts.map(product => {
            let points = 0;

            if (product.name.toLowerCase().includes(searchTerm.toLowerCase())) {
              points += 3;
            }

            if (product.short_description.toLowerCase().includes(searchTerm.toLowerCase())) {
              points += 2;
            }

            if (product.category.toLowerCase().includes(searchTerm.toLowerCase())) {
              points += 1;
            }

            return {...product, points};
          }).sort((a, b) => b.points - a.points);

          // Group results by category
          searchResultsProducts = searchResultsProducts.reduce(function (r, a) {
            r[a.category] = r[a.category] || [];
            r[a.category].push(a);
            return r;
          }, Object.create(null));

          // Set resultant results object and reset searchInProgress indicator
          this.searchResults = searchResultsProducts as never;
          this.searchInProgress = false;

        }).catch(() => {
          this.searchResults = [];
        }).finally(() => {
          this.searchInProgress = false;
        });
        /* eslint-enable @typescript-eslint/camelcase */
      }
    },
  },
  setup() {
    const searchResults = ref([]);
    const searchInProgress = ref(false);
    const searchTerm = ref("");
    const searchBarUsed = ref(false);

    return {
      categories: menuCategories,
      searchResults,
      searchInProgress,
      searchTerm,
      searchBarUsed,
      thumbsDownOutline,
      searchOutline,
    }
  }
})
