type StaticShippingMethodsType = {
  [key: string]: ShippingMethodType[];
}

type ShippingMethodType = {
  id: number;
  name: string;
  type: string;
  method_id: string;
  method_title: string;
  min_amount: number;
  total: number;
  hide_total?: boolean;
}

export const accountStates = [
  {
    code: "C",
    name: "La Coruña",
    postcode: "15",
  },
  {
    code: "VI",
    name: "Álava",
    postcode: "01",
  },
  {
    code: "AB",
    name: "Albacete",
    postcode: "02",
  },
  {
    code: "A",
    name: "Alicante",
    postcode: "03",
  },
  {
    code: "AL",
    name: "Almería",
    postcode: "04",
  },
  {
    code: "O",
    name: "Asturias",
    postcode: "33",
  },
  {
    code: "AV",
    name: "Ávila",
    postcode: "05",
  },
  {
    code: "BA",
    name: "Badajoz",
    postcode: "06",
  },
  {
    code: "PM",
    name: "Baleares",
    postcode: "07",
  },
  {
    code: "B",
    name: "Barcelona",
    postcode: "08",
  },
  {
    code: "BU",
    name: "Burgos",
    postcode: "09",
  },
  {
    code: "CC",
    name: "Cáceres",
    postcode: "10",
  },
  {
    code: "CA",
    name: "Cádiz",
    postcode: "11",
  },
  {
    code: "S",
    name: "Cantabria",
    postcode: "39",
  },
  {
    code: "CS",
    name: "Castellón",
    postcode: "12",
  },
  {
    code: "CE",
    name: "Ceuta",
    postcode: "51",
  },
  {
    code: "CR",
    name: "Ciudad Real",
    postcode: "13",
  },
  {
    code: "CO",
    name: "Córdoba",
    postcode: "14",
  },
  {
    code: "CU",
    name: "Cuenca",
    postcode: "16",
  },
  {
    code: "GI",
    name: "Gerona",
    postcode: "17",
  },
  {
    code: "GR",
    name: "Granada",
    postcode: "18",
  },
  {
    code: "GU",
    name: "Guadalajara",
    postcode: "19",
  },
  {
    code: "SS",
    name: "Guipúzcoa",
    postcode: "20",
  },
  {
    code: "H",
    name: "Huelva",
    postcode: "21",
  },
  {
    code: "HU",
    name: "Huesca",
    postcode: "22",
  },
  {
    code: "J",
    name: "Jaén",
    postcode: "23",
  },
  {
    code: "LO",
    name: "La Rioja",
    postcode: "26",
  },
  {
    code: "GC",
    name: "Las Palmas",
    postcode: "35",
  },
  {
    code: "LE",
    name: "León",
    postcode: "24",
  },
  {
    code: "L",
    name: "Lérida",
    postcode: "25",
  },
  {
    code: "LU",
    name: "Lugo",
    postcode: "27",
  },
  {
    code: "M",
    name: "Madrid",
    postcode: "28",
  },
  {
    code: "MA",
    name: "Málaga",
    postcode: "29",
  },
  {
    code: "ML",
    name: "Melilla",
    postcode: "52",
  },
  {
    code: "MU",
    name: "Murcia",
    postcode: "30",
  },
  {
    code: "NA",
    name: "Navarra",
    postcode: "31",
  },
  {
    code: "OR",
    name: "Orense",
    postcode: "32",
  },
  {
    code: "P",
    name: "Palencia",
    postcode: "34",
  },
  {
    code: "PO",
    name: "Pontevedra",
    postcode: "36",
  },
  {
    code: "SA",
    name: "Salamanca",
    postcode: "37",
  },
  {
    code: "TF",
    name: "Santa Cruz de Tenerife",
    postcode: "38",
  },
  {
    code: "SG",
    name: "Segovia",
    postcode: "40",
  },
  {
    code: "SE",
    name: "Sevilla",
    postcode: "41",
  },
  {
    code: "SO",
    name: "Soria",
    postcode: "42",
  },
  {
    code: "T",
    name: "Tarragona",
    postcode: "43",
  },
  {
    code: "TE",
    name: "Teruel",
    postcode: "44",
  },
  {
    code: "TO",
    name: "Toledo",
    postcode: "45",
  },
  {
    code: "V",
    name: "Valencia",
    postcode: "46",
  },
  {
    code: "VA",
    name: "Valladolid",
    postcode: "47",
  },
  {
    code: "BI",
    name: "Vizcaya",
    postcode: "48",
  },
  {
    code: "ZA",
    name: "Zamora",
    postcode: "49",
  },
  {
    code: "Z",
    name: "Zaragoza",
    postcode: "50",
  }
];

export const menuCategories = [
  {
    name: "Hamburguesas",
    slug: "hamburguesas",
    url: '/carta/Hamburguesas',
    id: 16,
  },
  {
    name: "Sandwichs",
    slug: "sandwichs",
    url: '/carta/Sandwichs',
    id: 19,
  },
  {
    name: "Perritos",
    slug: "perritos",
    url: '/carta/Perritos',
    id: 20,
  },
  {
    name: "Ensaladas",
    slug: "ensaladas",
    url: '/carta/Ensaladas',
    id: 22,
  },
  {
    name: "Bocatas",
    slug: "bocatas",
    url: '/carta/Bocatas',
    id: 21,
  },
  {
    name: "Combinados",
    slug: "combinados",
    url: '/carta/Combinados',
    id: 25,
  },
  {
    name: "Raciones",
    slug: "raciones",
    url: '/carta/Raciones',
    id: 26,
  },
  {
    name: "Postres",
    slug: "postres",
    url: '/carta/Postres',
    id: 24,
  },
  {
    name: "Helados",
    slug: "helados",
    url: '/carta/Helados',
    id: 114,
  },
  {
    name: "Refrescos",
    slug: "refrescos",
    url: '/carta/Refrescos',
    id: 17,
  }
];

export const shippingMethods: StaticShippingMethodsType = {
  /* eslint-disable @typescript-eslint/camelcase */
  "47140": [
    {
      id: 1,
      name: "Envío a domicilio",
      type: "delivery",
      method_id: "free_shipping",
      method_title: "Envío gratuito",
      total: 0,
      min_amount: 15,
    },
    {
      id: 2,
      name: "Recogida en el local",
      type: "pickup",
      method_id: "flat_rate",
      method_title: "Recogida local",
      total: 0,
      min_amount: 0,
      hide_total: true,
    },
  ],
  "47151": [
    {
      id: 5,
      name: "Envío a domicilio",
      type: "delivery",
      method_id: "flat_rate",
      method_title: "Precio fijo",
      total: 2,
      min_amount: 15,
    },
    {
      id: 6,
      name: "Recogida en el local",
      type: "pickup",
      method_id: "flat_rate",
      method_title: "Recogida local",
      total: 0,
      min_amount: 0,
      hide_total: true,
    },
  ],
  "default": [
    {
      id: 4,
      name: "Recogida en el local",
      type: "pickup",
      method_id: "flat_rate",
      method_title: "Recogida local",
      total: 0,
      min_amount: 0,
      hide_total: true,
    },
  ],
  /* eslint-enable @typescript-eslint/camelcase */
}
