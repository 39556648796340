<template>
  <ion-page>
    <ion-header class="border-bottom-thin">
      <ion-toolbar color="primary">
        <ion-buttons slot="start">
          <ion-back-button text=""/>
        </ion-buttons>
        <ion-title class="ion-text-capitalize">Buscar productos</ion-title>
      </ion-toolbar>


      <ion-progress-bar type="indeterminate" v-if="searchInProgress"/>
      <ion-progress-bar style="--background: none" v-else/>


      <ion-searchbar @ionChange="searchProduct" @ionClear="clearSearchTerm"
                     enterkeyhint="Buscar"
                     placeholder="Buscar un producto..."
                     debounce="750" ref="autofocusInput"/>
    </ion-header>
    <ion-content class="background-light">

      <!-- START: No Search Term provided error -->
      <ion-grid class="full-height-centered" v-if="searchTerm.length === 0">
        <ion-row>
          <ion-icon :icon="searchOutline" size="large"></ion-icon>
          <p>Introduce un término de búsqueda y te mostraremos los productos que coincidan.</p>
        </ion-row>
      </ion-grid>
      <!-- END: No Search Term provided error -->


      <!-- START: No results found error -->
      <ion-grid class="full-height-centered"
                v-else-if="searchTerm.length !== 0 && Object.keys(searchResults).length === 0">
        <ion-row style="padding: 50px">
          <ion-icon :icon="thumbsDownOutline" size="large"></ion-icon>
          <h3>Ooops!</h3>
          <p>No hemos encontrado ningún producto que coincida con tu búsqueda.</p>
        </ion-row>
      </ion-grid>
      <!-- END: No results found error -->


      <!-- START: Search results list -->
      <ion-list lines="full" v-else-if="searchTerm.length !== 0 && Object.keys(searchResults).length">
        <ion-item-group v-for="(products, category) in searchResults" :key="category">
          <ion-item-divider class="border-bottom-thin" sticky>
            <ion-label class="ion-text-capitalize">{{ category }}</ion-label>
          </ion-item-divider>
          <ion-item button
                    v-for="product in products" :key="product.id"
                    routerDirection="forward"
                    :router-link="`/carta/${product.category}/${product.slug}`">
            <p>{{ product.name }}</p>
          </ion-item>
        </ion-item-group>
      </ion-list>
      <!-- END: Search results list -->


    </ion-content>
  </ion-page>
</template>

<script lang="ts">
import {
  IonPage, IonContent, IonList, IonItem, IonHeader,
  IonToolbar, IonSearchbar, IonButtons, IonRow,
  IonGrid, IonIcon, IonBackButton, IonItemDivider,
  IonItemGroup, IonProgressBar, IonLabel, IonTitle
} from '@ionic/vue';
import { menuCategories } from "@/config/StaticData";
import { defineComponent, ref } from "vue";
import { searchStoreProducts } from "@/services/Api";
import { searchOutline, thumbsDownOutline } from "ionicons/icons";

export default defineComponent({
  name: 'Search',
  components: {
    IonPage, IonContent, IonList, IonItem, IonHeader,
    IonToolbar, IonSearchbar, IonButtons, IonRow,
    IonGrid, IonIcon, IonBackButton, IonItemDivider,
    IonItemGroup, IonProgressBar, IonLabel, IonTitle
  },
  mounted() {
    const autofocusInput: any = this.$refs.autofocusInput;
    setTimeout(() => {
      autofocusInput.$el.setFocus()
    }, 1000)
  },
  methods: {
    clearSearchTerm: function () {
      this.searchBarUsed = false;
      this.searchInProgress = false;
      this.searchResults = [];
    },
    searchProduct: function (event: any) {
      const searchTerm = event.target.value;
      this.searchTerm = searchTerm;

      if (searchTerm.length !== 0) {
        this.searchInProgress = true;
        searchStoreProducts(searchTerm).then((data) => {
          /* eslint-disable @typescript-eslint/camelcase */
          let searchResultsProducts: { id: number; slug: string; name: string; category: string; short_description: string }[];

          // Remove unneeded properties from store search results
          searchResultsProducts = data.map((product: any) => {
            const {id, slug, name, categories, short_description} = product;
            return {id, slug, name, category: categories[0]['slug'], short_description}
          })

          // Rank results by relevance
          searchResultsProducts = searchResultsProducts.map(product => {
            let points = 0;

            if (product.name.toLowerCase().includes(searchTerm.toLowerCase())) {
              points += 3;
            }

            if (product.short_description.toLowerCase().includes(searchTerm.toLowerCase())) {
              points += 2;
            }

            if (product.category.toLowerCase().includes(searchTerm.toLowerCase())) {
              points += 1;
            }

            return {...product, points};
          }).sort((a, b) => b.points - a.points);

          // Group results by category
          searchResultsProducts = searchResultsProducts.reduce(function (r, a) {
            r[a.category] = r[a.category] || [];
            r[a.category].push(a);
            return r;
          }, Object.create(null));

          // Set resultant results object and reset searchInProgress indicator
          this.searchResults = searchResultsProducts as never;
          this.searchInProgress = false;

        }).catch(() => {
          this.searchResults = [];
        }).finally(() => {
          this.searchInProgress = false;
        });
        /* eslint-enable @typescript-eslint/camelcase */
      }
    },
  },
  setup() {
    const searchResults = ref([]);
    const searchInProgress = ref(false);
    const searchTerm = ref("");
    const searchBarUsed = ref(false);

    return {
      categories: menuCategories,
      searchResults,
      searchInProgress,
      searchTerm,
      searchBarUsed,
      thumbsDownOutline,
      searchOutline,
    }
  }
})
</script>

<style lang="scss" scoped>
ion-item-divider {
  --background: #f9f9f9;
  --padding-top: 20px
}
</style>
